<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Leads</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-5">
                <h3 class="mb-0" id="leads_main_heading">Leads</h3>
              </div>
              <div class="col-7 text-right">
                <div class="assignee-container">
                  <el-switch v-model="hubspotUnSyncedOnly" @change="filterHubspotSyncedOnly"></el-switch> <span class="text-sm">Hubspot Un-Synced Only</span>
                  <label class="text-sm mr-1 ml-2">Assignee:</label>
                  <el-select
                    clearable 
                    v-model="hubspotOwnerId"
                    @change="assigneeChanged"
                    class="sales-rep-select"
                    placeholder="Select">
                    <el-option-group
                      v-for="(item, _status) in venueSpecialistsDeactiveGrouped"
                      :key="_status"
                      :label="_status">
                      <el-option
                        v-for="(venueSpecialist, index) in item" :key="index"
                        :label="venueSpecialist.label"
                        :value="venueSpecialist.value">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </div>
                <a href="#" @click.stop="reQueueLeadsToHubspot" v-if="isAdmin"   class="btn btn-primary btn-sm">Requeue to Hubspot</a>
                <a href="#" @click.stop="toCreateLeadPage" style="display:none" class="btn btn-primary">Create Lead</a>
                <a href="#" @click.stop="modalExportLeads=true"  class="btn btn-primary btn-sm">Export</a>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 text-right">
              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Press ENTER to search"
                  @keyup.enter="search"
                ></base-input>
              </div>
            </div>

            <el-table
              :data="leads"
              row-key="id"
              v-loading="leadsLoading"
              header-row-class-name="thead-light"
              id="leads_table"
              @sort-change="sortChange"
              @row-click="rowClicked"
            >
              <el-table-column
                label="Name"
                prop="name"
                min-width="100px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.fullName
                  }}</span>
                  <button class="btn btn-sm btn-primary" v-if="modalRequeueToHs && notInHsQueue(row.lead_id)" @click.stop="addToHsQueue(row.lead_id, row.fullName)">Add to queue</button>
                </template>
              </el-table-column>

              <el-table-column
                label="Type"
                prop="eventType"
                min-width="70px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.eventType }}
                </template>
              </el-table-column>

              <el-table-column
                label="Country"
                prop="country"
                min-width="60px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.country }}
                </template>
              </el-table-column>

              <el-table-column
                label="Venue/s"
                prop="venues"
                min-width="120px"
              >
                <template v-slot="{ row }">
                  {{ (( row.enquiry_venue && row.enquiry_venue.name) || '') | truncate(35, "...") }}
                </template>
              </el-table-column>

              <el-table-column label="Est. Budget" prop="estimatedBudget" sortable="custom">
                <template v-slot="{ row }">
                  ${{ row.estimatedBudget }}
                </template>
              </el-table-column>

              <el-table-column label="Est. Guests" prop="estimatedGuests" sortable="custom">
                <template v-slot="{ row }">
                  {{ row.estimatedGuests }}
                </template>
              </el-table-column>

              <el-table-column
                label="Enq. Date"
                prop="createdDate"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.createdDate | moment("DD MMM YYYY")  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                width="100px"
                prop="enquiry_status"
              >
                <template v-slot="{ row }">
                  <badge
                    rounded 
                    :type="row.enquiry_status  == 'new' ? 'success' : 'warning'">
                    {{ row.enquiry_status }}
                  </badge>
                </template>
              </el-table-column>

              <el-table-column
                label="Assignee"
                prop="Assignee"
                min-width="120px"
              >
                <template v-slot="{ row }">
                  <el-select
                    v-model="row.hubspot_owner_id"
                    @change="updateAssignee(row.lead_id,row.hubspot_owner_id)"
                    class="sales-rep-select"
                    placeholder="Select">
                    <el-option-group
                      v-for="(item, _status) in venueSpecialistsGrouped"
                      :key="_status"
                      :label="_status">
                      <el-option
                        v-for="(venueSpecialist, index) in item" :key="index"
                        :label="venueSpecialist.label"
                        :value="venueSpecialist.value"
                        :disabled="!venueSpecialist.active">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                min-width="50px"
              >
                <template v-slot="{ row }">
                  <el-tooltip class="item" effect="dark" content="Hubspot syncing failed. Click for more info." placement="top-start">
                    <i class="el-icon-warning text-danger text-xl" v-if="! row.hubspot_object_id" @click.stop="showHubspotSyncDetails(row)"></i>
                  </el-tooltip>
                  
                  <el-tooltip class="item" effect="dark" content="Synced at Hubspot" placement="top-start">
                    <i class="el-icon-success text-success text-xl" v-if="row.hubspot_object_id"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <QueueLeadsToHs v-if="modalRequeueToHs" :queue.sync="hsQueue" @cancel="modalRequeueToHs = false"></QueueLeadsToHs>
    <modal
      :show.sync="modalExportLeads"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-md"
    >
      <ExportLeads :country="country"/>
    </modal>
    <modal
      v-if="modalLeadSyncStatus"
      :show.sync="modalLeadSyncStatus"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-md"
    >
      <LeadHubspotSyncStatus :leadId="syncStatusSelectedLeadId" @close="closeHubspotSyncStatus" @delete="onUnSyncedContactDeleted"/>
    </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Loading, OptionGroup, Tooltip, Switch } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import tableMixin from "@/helpers/remoteTableMixin";
import { mapState } from "vuex";
import { LeadService } from "@/services";
import ExportLeads from '@/views/Widgets/ExportLeads';
import QueueLeadsToHs from '@/views/Pages/Leads/QueueLeadsToHs';
import LeadHubspotSyncStatus from '@/views/Widgets/Leads/LeadHubspotSyncStatus';
export default {
  mixins: [tableMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    ExportLeads,
    QueueLeadsToHs,
    LeadHubspotSyncStatus,
  },
  data() {
    return {
      leads: [],
      leadsLoading: true,
      modalExportLeads:false,
      modalRequeueToHs: false,
      modalLeadSyncStatus: false,
      syncStatusSelectedLeadId: 0,
      hsQueue: [],
      hubspotOwnerId: '',
      hubspotUnSyncedOnly: false,
    };
  },
  async mounted() {
    await this.$store.dispatch('venues/getVenueSpecialists');
  },
  created() {
    this.loadPage(this.currentPage);
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
    venueSpecialists() {
      let vcs = this.$store.state.venues.venueSpecialists;
      let data = [];
      for (let vc in vcs) {
        vc = vcs[vc];
        if(vc.hubspot_owner_id && vc.country.toUpperCase() === this.country) {
          data.push({
            label: vc.name,
            value: vc.hubspot_owner_id,
            active: vc.active,
            disabled: vc.disabled,
          });
        }
      }
      data.sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });
      data.unshift({
        label: 'All',
        value: '',
      });
      return data;
    },
    venueSpecialistsGrouped() {
      let vcs = this.$store.state.venues.venueSpecialists;
      let data = {
        active: [],
        inactive: [],
      };
      for (let vc in vcs) {
        vc = vcs[vc];
        if(vc.hubspot_owner_id && vc.country.toUpperCase() === this.country) {
          data[vc.active ? 'active' : 'inactive'].push({
            label: vc.name,
            value: vc.hubspot_owner_id,
            active: !!(vc.active),
          });
        }
      }
      data['active'].sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });
      data['inactive'].sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });
      data['active'].unshift({
        label: 'Unassigned',
        value: '',
        active: true,
      });
      return data;
    },
    venueSpecialistsDeactiveGrouped() {
      let vcs = this.$store.state.venues.venueSpecialists;
      let data = {
        active: [],
        deactivated: [],
      };
      for (let vc in vcs) {
        vc = vcs[vc];
        if(vc.hubspot_owner_id && vc.country.toUpperCase() === this.country) {
          data[vc.disabled ? 'deactivated' : 'active'].push({
            label: vc.name,
            value: vc.hubspot_owner_id,
            disabled: !!(vc.disabled),
          });
        }
      }
      data['active'].sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });
      data['deactivated'].sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });
      data['active'].unshift({
        label: 'All',
        value: '',
        active: true,
      });
      return data;
    },
  },
  methods: {
    rowClicked(row) {
      this.$router.push({ name: 'LeadDetails', params: {id: row.lead_id} });
    },

    async loadPage(page, search, sortBy, sortOrder) {
      this.leadsLoading = true;
      let leadData = await LeadService.paginate(page, search, sortBy, sortOrder, this.country, this.hubspotOwnerId, this.hubspotUnSyncedOnly);
      this.leads = leadData.data;
      this.pagination.total = parseInt(leadData.meta.total);
      this.pagination.perPage = parseInt(leadData.meta.per_page);
      this.leadsLoading = false;
    },

    async assigneeChanged(hubspotOwnerId) {
      this.hubspotOwnerId = hubspotOwnerId;
      this.Loading = true;
      this.pagination.currentPage = 1;
      await this.loadPage(this.pagination.currentPage, this.searchQuery, this.sortBy, this.sortOrder);
      this.Loading = false;
    },

    async updateAssignee(leadId, hubspotOwnerId) {
      this.loading = true;
      let leadDetails = await LeadService.updateHubspotOwner({
        hubspot_owner_id: hubspotOwnerId,
      }, leadId).catch((error) => {
        if (error && error.response && error.response.status === 422) {
          this.$store.dispatch('alert/error', error.response.data.error.message, { root: true });
        } else {
          this.$store.dispatch('alert/error', error, { root: true });
        }
        return [];
      });
      this.loading = false;
    },

    toCreateLeadPage() {
      this.$router.push({name: 'NewLead'});
    },

    reQueueLeadsToHubspot() {
      this.modalRequeueToHs = true;
    },

    addToHsQueue(id, name) {
      this.hsQueue.push({
        id,
        name,
      });
    },

    notInHsQueue(id) {
      let result =  this.hsQueue.filter(function(v,i) {
        return v.id === id;
      });
      return !result.length;
    },

    showHubspotSyncDetails(row) {
      this.modalLeadSyncStatus = true;
      this.syncStatusSelectedLeadId = row.lead_id;
    },

    async filterHubspotSyncedOnly() {
      this.pagination.currentPage = 1;
      await this.loadPage(this.pagination.currentPage, this.searchQuery, this.sortBy, this.sortOrder);
    },

    closeHubspotSyncStatus() {
      this.modalLeadSyncStatus = false;
    },

    async onUnSyncedContactDeleted() {
      this.modalLeadSyncStatus = false;
      await this.loadPage(this.pagination.currentPage, this.searchQuery, this.sortBy, this.sortOrder);
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
.el-table .cell {
  word-break: normal;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f6f9fc !important;
  cursor: pointer;
}
.el-select-group__title {
  text-transform: capitalize;
}
.assignee-container {
  display:inline;
  margin:10px;
}
</style>
