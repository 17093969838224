<template>
  <div class="queue_to_hubspot_modal" v-loading="loading">
    <h3 class="h3 text-center mt-4">Add to queue</h3>
    <div class="queue">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="item in queue" :key="item.id">{{item.name}}
          <span aria-hidden="true" class="float-right" role='button' @click.stop="remove(item.id)">&times;</span>
        </li>
          
      </ul>
    </div>
    <div class="footer flex text-center">
      <button class="btn btn-secondary btn-sm" @click.stop="cancel">Cancel</button>
      <button class="btn btn-primary btn-sm" @click.stop="sendToHs">Send To Hubspot</button>
    </div>
  </div>
</template>
<script>
import { LeadService } from "@/services";
export default {
  props: {
    queue: {
      required: true,
    },
  },
  data() {
    return{
      loading: false,
    };
  },
  methods:{
    remove(id) {
      let result =  this.queue.filter(function(v,i) {
        return v.id !== id;
      });
      this.$emit('update:queue', result);
    },
    cancel() {
      this.$emit('cancel');
    },
    async sendToHs() {
      this.loading = true;
      await LeadService.queueLeadsToHs({
        leads: this.queue,
      });
      this.loading = false;
      this.$emit('update:queue', []);
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped>
.queue_to_hubspot_modal {
	position: fixed;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	width: 350px;
	height: 400px;
	right: 150px;
	top: 150px;
	background: #fff;
	border-radius: 4px;
	z-index: 100;
}
.queue {
    height: 292px;
    overflow-y:auto;
    border-top: 1px solid #ddd
}
.footer {
    padding: 10px 0;
    border-radius: 4px 0px;
}
</style>