<template>
  <div class="container lead-hubspot-sync">
    <div class="row">
      <div class="col-12 mt-4">
        <h3>Hubspot Sync Status</h3>
        <hr class="mt-0 mb-2"/>
      </div>
    </div>
    <div class="row" v-loading="loading">
      <div class="col-9 mt-4">
        <h4 class="mb-0">Email</h4>
        <small>{{leadData.email}}</small>
      </div>
      <div class="col-3 mt-4 text-right">
        <el-alert
          :title="leadData.email_valid ? 'Valid' : 'Invalid'"
          :type="leadData.email_valid ? 'success' : 'error'"
          :closable="false"
          :center="true"
          show-icon>
        </el-alert>
      </div>
      <div class="col-9 mt-4">
        <h4 class="mb-0">Event Date</h4>
        <small>{{leadData.event_date}}</small>
      </div>
      <div class="col-3 mt-4 text-right">
        <el-alert
          :title="leadData.event_date_valid ? 'Valid' : 'Invalid'"
          :type="leadData.event_date_valid ? 'success' : 'error'"
          :closable="false"
          :center="true"
          show-icon>
        </el-alert>
      </div>
      <div class="col-9 mt-4">
        <h4 class="mb-0">Event Type</h4>
        <small>{{leadData.event_type}}</small>
      </div>
      <div class="col-3 mt-4 text-right">
        <el-alert
          :title="leadData.event_type_valid ? 'Valid' : 'Invalid'"
          :type="leadData.event_type_valid ? 'success' : 'error'"
          :closable="false"
          :center="true"
          show-icon>
        </el-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <hr class="mt-0 mb-2"/>
      </div>
      <div class="col-12">
        <form>
          <h4 class="text-muted mb-4">
            Update Lead Information <br/><small>Correct the above mentioned issues and re-sync to Hubspot.</small>
          </h4>
          <div>
            <div class="row">
              <div class="col-lg-12">
                <base-input
                  type="email"
                  label="Email"
                  id="lead_information_email"
                  placeholder="Email"
                  v-model="leadFormData.email"
                >
                </base-input>
              </div>
              <div class="col-lg-6">
                <base-input
                  label="Event Type"
                >
                  <el-select 
                    v-model="leadFormData.event_type" 
                    id="lead_information_event_type"
                    placeholder="Event Type">
                    <el-option
                      v-for="(label, key) in eventTypes"
                      :key="key"
                      :label="label"
                      :value="key">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="form-control-label"> Event Date </label>
                  <div class="has-label">
                    <date-picker
                      name="date"
                      label="Event Date"
                      id="lead_information_event_date"
                      placeholder="Event Date"
                      v-model="leadFormData.event_date"
                      :config="dateOptions"
                    ></date-picker>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-4 text-right">
                <el-button type="danger" :loading="deleteLoading" @click="deleteContact" icon="el-icon-delete">Delete</el-button>
                <el-button type="primary" :loading="loading" @click="saveAndSync" icon="el-icon-position">Save & Sync</el-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Button, Select, Option, MessageBox } from "element-ui";
import datePicker from "vue-bootstrap-datetimepicker";
import { eventTypes } from '@/../app.config';
import LeadService from '@/services/lead.service';

export default {
  props: {
    leadId: {
      required: true,
      type: Number,
    },
  },
  components: {
    [Alert.name]: Alert,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [MessageBox.name]: MessageBox,
    datePicker,
  },
  data() {
    return {
      leadFormData: {},
      leadData: {},
      loading: false,
      deleteLoading:false,
      dateOptions: {
        format: "YYYY-MM-DD",
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.leadData = await LeadService.getLeadHubspotSyncStatus(this.leadId);
    this.loading = false;
    this.leadFormData = {
      email: this.leadData.email,
      event_date: this.leadData.event_date,
      event_type: this.leadData.event_type,
    };
  },
  computed: {
    eventTypes: () => eventTypes,
  },
  methods: {
    async saveAndSync() {
      this.loading = true;
      await LeadService.saveAndResyncToHubspot(this.leadId, this.leadFormData);
      this.loading = false;
      this.$emit('close');
    },

    async deleteContact() {
      MessageBox.confirm('Are you certain you wish to delete this contact?', 'Delete Lead', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.deleteLoading = true;
        await LeadService.deleteLead(this.leadId);
        this.deleteLoading = false;
        this.$emit('delete');
      });
      
    },
  },
};
</script>
<style>
.lead-hubspot-sync .el-alert {
  border-radius: 20px;
  padding: 3px 10px;
}
</style>