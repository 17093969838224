<template>
  <export-date-range title="Export Leads" export_path="/v1/export/leads/" :params="{country}"/>
</template>
<script>
import ExportDateRange from "@/views/Widgets/Reports/ExportDateRange";
export default {
  props: {
    country: {
      type: String,
    },
  },
  components: {
    ExportDateRange,
  },
};
</script>